import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";

import { Locale, getLangLocale } from "@ag/i18n";
import {
  SIGN_IN_AS_PARAM,
  USER_AUTH_DATA_STORAGE_KEY,
} from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { useEventListener, useSearchParam } from "@ag/utils/hooks";
import { AuthData } from "@ag/utils/schemas";
import { ToastNotification } from "@ag/utils/services";
import { loadFromLocalStorage, saveToLocalStorage } from "@ag/utils/storage";

import { useLocaleContext } from "~contexts/locales";
import { queryCache } from "~lib/react-query";

import { getCurrentUser } from "../api/get-current-user";
import { signIn as signInRequest } from "../api/sign-in";
import { signInAs } from "../api/sign-in-as";
import { signOut as signOutRequest } from "../api/sign-out";
import { CurrentUser } from "../entities/current-user";

const ANONYMOUS_SESSION_LANG_KEY = "lang";

type TSessionContext = {
  currentUser: CurrentUser | undefined;
  userAuthData: AuthData | null;

  isLoading: boolean;
  isSignedIn: boolean;
  isAdminMode: boolean;

  fetchCurrentUser: () => Promise<CurrentUser>;
  setUserAuthData: (userAuthData: AuthData | null) => void;
  signIn: (
    data: Parameters<typeof signInRequest>[0],
    onSuccess?: () => void,
  ) => Promise<void>;
  signOut: (options?: { hasSessionExpired: boolean }) => Promise<void>;
};

const SessionContext = createContext<TSessionContext | null>(null);

type SessionStore = {
  currentUser: TSessionContext["currentUser"];
  userAuthData: TSessionContext["userAuthData"];

  setCurrentUser: (currentUser: TSessionContext["currentUser"]) => void;
  setUserAuthData: (userAuthData: TSessionContext["userAuthData"]) => void;
  resetSession: () => void;
};

const useSessionStore = create<SessionStore>()(set => ({
  currentUser: undefined,
  userAuthData: loadFromLocalStorage<AuthData | null>(
    USER_AUTH_DATA_STORAGE_KEY,
  ),

  setCurrentUser: currentUser => set({ currentUser }),
  setUserAuthData: userAuthData => set({ userAuthData }),
  resetSession: () => set({ currentUser: undefined, userAuthData: null }),
}));

const sessionBroadcastChannel = new BroadcastChannel("session");

export const SessionProvider = ({
  children,
}: React.PropsWithChildrenRequired) => {
  const {
    currentUser,
    userAuthData,
    setCurrentUser,
    setUserAuthData,
    resetSession,
  } = useSessionStore();

  const [isLoading, setIsLoading] = useState(false);
  const [langParam] = useSearchParam("lang");
  const [redirectSearchParams] = useSearchParam("redirect");

  const [signInAsParam, setSignInAsParam] = useSearchParam(SIGN_IN_AS_PARAM);

  const navigate = useRef(useNavigate());
  const { setLocale } = useLocaleContext();

  const langLocale = useMemo(() => getLangLocale(langParam), [langParam]);

  const fetchCurrentUser = useCallback(async () => {
    const currentUser = await getCurrentUser();

    // Role must be set before currentUser to ensure we don't incorrectly redirect to missing information view
    setCurrentUser(currentUser);

    return currentUser;
  }, [setCurrentUser]);

  // Sync auth data between windows/tabs after refresh
  sessionBroadcastChannel.onmessage = event => {
    if (event.data === "auth-data:updated") {
      setUserAuthData(loadFromLocalStorage(USER_AUTH_DATA_STORAGE_KEY));
    }
  };

  /**
   * This event will be triggered from the interceptor when the session is expired (401 response)
   */
  useEventListener("session-expired", () => {
    signOut({ hasSessionExpired: true });
  });

  useEffect(() => {
    const consumeSignInAsAuthDataParams = async () => {
      const signInAsAuthData = await signInAs(signInAsParam!);
      saveToLocalStorage(USER_AUTH_DATA_STORAGE_KEY, signInAsAuthData);

      setSignInAsParam(undefined);
      await fetchCurrentUser();
      setIsLoading(false);
      setUserAuthData(signInAsAuthData);

      navigate.current(window.location);
    };

    if (signInAsParam) {
      setIsLoading(true);
      consumeSignInAsAuthDataParams();
    }
  }, [signInAsParam, fetchCurrentUser, setSignInAsParam, setUserAuthData]);

  /**
   * Set the locale for this session
   */
  useEffect(() => {
    if (isLoading) return;

    // If an admin is logged in, use english
    if (userAuthData?.adminId) {
      setLocale(Locale.En);
      return;
    }

    // If the user is logged in, use their preferred locale
    if (currentUser) {
      setLocale(currentUser.locale);
      return;
    }

    // If user is not logged in, check for the custom "lang" param
    if (langLocale) {
      setLocale(langLocale);

      /**
       * Store this session value, so the value is kept while anonymous,
       * without having to add it to every internal url.
       */
      saveToLocalStorage(ANONYMOUS_SESSION_LANG_KEY, langLocale);
      return;
    }

    // Finally check for lang in localStorage, used as a anonymous session value.
    const storedLocale = loadFromLocalStorage(ANONYMOUS_SESSION_LANG_KEY);
    if (storedLocale) {
      setLocale(storedLocale as Locale);
      return;
    }
  }, [currentUser, userAuthData, langParam, langLocale, isLoading, setLocale]);

  const signIn = useCallback(
    async (
      data: Parameters<typeof signInRequest>[0],
      onSuccess?: () => void,
    ) => {
      try {
        setIsLoading(true);

        const userAuthData = await signInRequest(data);
        saveToLocalStorage(USER_AUTH_DATA_STORAGE_KEY, userAuthData);
        setUserAuthData(userAuthData);

        await fetchCurrentUser();

        if (onSuccess) onSuccess();

        navigate.current(redirectSearchParams || "/");
      } catch (error) {
        ToastNotification.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchCurrentUser, redirectSearchParams, setUserAuthData],
  );

  const signOut = useCallback<TSessionContext["signOut"]>(
    async options => {
      const { hasSessionExpired = false } = options || {};

      try {
        if (!hasSessionExpired) {
          await signOutRequest();
        }

        resetSession();
        localStorage.removeItem(USER_AUTH_DATA_STORAGE_KEY);

        if (!hasSessionExpired) {
          navigate.current("/login");
        } else {
          navigate.current(
            "/login" + getSearchParams({ redirect: location.pathname }),
          );
        }

        queryCache.clear();
      } catch (error) {
        ToastNotification.error(error);
      }
    },
    [resetSession],
  );

  const contextValue = useMemo(
    () => ({
      currentUser,
      userAuthData,

      isLoading,
      isSignedIn: Boolean(userAuthData && currentUser),
      isAdminMode: Boolean(userAuthData?.adminId),

      fetchCurrentUser,
      setUserAuthData,
      signIn,
      signOut,
    }),
    [
      currentUser,
      userAuthData,
      setUserAuthData,
      isLoading,
      fetchCurrentUser,
      signIn,
      signOut,
    ],
  );

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("useSessionContext must be used within a SessionProvider");
  }

  return context;
};
